<template>
  <div class="fixed-card">
    <b-card
        v-show="isExistPdfLocal && pdfUrl"
        class="pdf-dropzone-card pdf-card"
    >
      <pdf
          :src="pdfUrl"
          :page="currentPage"
          class="pdf-viewer"
          style="display: inline-block;width: 100%;"
      />
    </b-card>
    <b-card
        v-show="!isExistPdfLocal"
        :key="componentKey"
        class="pdf-dropzone-card dropzone-card"
    >
      <vue-dropzone
          id="customdropzone"
          ref="myDropzone"
          :options="dropzoneOptions"
          @vdropzone-sending="onSending"
          @vdropzone-success="onSuccess"
      />
    </b-card>
  </div>
</template>

<script>
import {BCard} from "bootstrap-vue";
import Pdf from 'vue-pdf';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import axiosHeaders from "@/libs/axios/header";

export default {
  components: {
    BCard,
    Pdf,
    VueDropzone: vue2Dropzone,
  },
  props: {
    contractId: {
      type: [Number, String],
      default: null,
    },
    isExistPdf: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "update",
    }
  },
  data() {
    return {
      pdfUrl: null,
      currentPage: 1,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API_URL}contracts/${this.contractId}/upload-file`,
        headers: axiosHeaders,
      },
      isExistPdfLocal: this.isExistPdf,
      componentKey: 1,
    };
  },
  watch: {
    contractId: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.contractId = newVal
          this.dropzoneOptions.url = `${process.env.VUE_APP_API_URL}contracts/${newVal}/upload-file`;
          this.componentKey += 1; // for re-render dropzone component with new url
        }
      },
    },
    isExistPdf: {
      immediate: true,
      handler(newVal) {
        this.isExistPdfLocal = newVal
        if(newVal){
          this.getPdf()
        }
      },
    },
  },
  mounted() {
    if(this.isExistPdfLocal) this.getPdf()
  },
  methods: {
    onSuccess(file, response){
      if(response.success){
        this.isExistPdfLocal = true
        this.getPdf()
      }
    },
    getPdf() {
      this.pdfUrl = `${process.env.VUE_APP_API_URL}contracts/${this.contractId}/pdf`;
    },
    onSending(file, xhr, formData) {
      formData.append('type', this.type);
    },
  },
}
</script>

<style>
.pdf-dropzone-card{
  height: 100%;
  width: 100%;
}
.fixed-card{
  height: 70vh;
  position: sticky;
  top: 20vh;
}
.pdf-card{
  display: flex;
  justify-content: center;
  align-items: center;
}
#customdropzone{
  height: 100%;
}
</style>
